<template>
  <div style="min-height: calc(100vh - 158px);" class="card">
    <div style="background: #DBE7F2" class="p-2">
      <TitleButton
          btnTitle="Add New"
          :showBtn="false"
          :showSettingBtn="false"
          :showAddNew="true"
          title="Cost Centre List"
          @onClickAddNewButton="onClickAddNewButton"
          @onClickSettingButton="onClickSettingButton"
      />
      <div class="row">
        <div class="col-10">
          <label for="colFormLabel" class="col-form-label">Search</label>
          <input class="form-control" type="text" name="status" v-model="quick_search">
        </div>
        <div class="col-2">
          <label for="">&nbsp;</label>
          <button
              style="min-width: 64px;margin-top: 1rem;"
              @click="onClickSearchButton"
              class="btn btn-primary waves-effect waves-float waves-light form-control"
          >Go
          </button>
        </div>
      </div>
    </div>

    <div class="col-12 mt-2">
      <div class="d-flex justify-content-end px-2">
        <button
            @click="exportTable"
            class="btn btn-outline-secondary btn-sm btn-download-invoice waves-effect"
        >
          Export
        </button>
      </div>
    </div>
    <ListTable/>
    <div class="mb-2"></div>
    <div class="px-2 position-absolute bottom-0">
      <Pagination
          :offset="offset"
          @onPageChange="onPageChange"
          ref="pagination"
      />
    </div>

    <CostCentreAddModal
        modalTitle="Add Cost Centre"
        :showParent="true"
        ref="costCentre"
        :formData="costCentreAddData"
        @onSubmitForm="handleCostCentreSubmit"
    />
  </div>
</template>

<script>
import TitleButton from '@/components/atom/TitleButton'
import ListTable
                   from '@/components/molecule/procurement/organization-settings/admin-setup/cost-centre/CostCentreListingTable.vue'
import CostCentreAddModal
                   from '@/components/molecule/procurement/organization-settings/admin-setup/cost-centre/CostCentreAddModal.vue'

import Pagination     from '@/components/atom/Pagination'
import Loader         from '@/components/atom/LoaderComponent'
import {inject}       from "vue";
import {mapMutations} from "vuex";

export default {
  name: 'CostCentreList',

  components: {
    TitleButton,
    ListTable,
    CostCentreAddModal,
    Pagination,
    Loader,
  },

  data: () => ({
    loading          : false,
    quick_search     : null,
    costCentreAddData: {
      cost_centre_name      : '',
      cost_centre_short_name: '',
      cost_centre_id        : null,
    },
  }),

  computed: {},

  methods: {
    onClickAddNewButton() {
      this.$refs.costCentre.toggleModal()
    },

    onClickSettingButton() {
      alert('Setting Button clicked');
    },

    onClickSearchButton() {
      alert('Search Button clicked');
    },

    handleCostCentreSubmit() {
      console.log(this.costCentreAddData, '>>>>>>>>>> ')
    },

    getJournals() {
      //todo
    },

    onPageChange() {
      //todo
    },

    exportTable() {
      //todo
    },
  },

  setup() {

  },

  mounted() {
  }
}
</script>
